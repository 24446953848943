import React from 'react'
import { Row, Col, Input, Popover } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/messagebox/actions'
import { injectIntl } from 'react-intl'
import { SmileOutlined, SendOutlined } from '@ant-design/icons'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
// import styles from '../../style.module.scss'

/* eslint-disable jsx-a11y/accessible-emoji */
const mapStateToProps = ({ messagebox }) => ({ messagebox })
@connect(mapStateToProps)
class MessageBoxFooter extends React.Component {
  state = {
    textMessage: '',
  }

  sendMessage = message => {
    const { dispatch } = this.props

    dispatch({
      type: actions.SEND_FEEDBACK,
      payload: {
        message,
      },
    })
  }

  addEmoji = event => {
    const { textMessage } = this.state
    const textEmoji = `${textMessage}${event.native}`
    this.setState({ textMessage: textEmoji })
  }

  onPressEnter = () => {
    const { textMessage } = this.state
    if (textMessage !== '') {
      this.sendMessage(textMessage)
      this.setState({ textMessage: '' })
    }
  }

  render() {
    const { textMessage } = this.state
    const { intl } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="messageboxfooter">
          {/* <Col span={1} className="text-center" /> */}
          <Col span={2} className="text-center">
            <Popover
              placement="top"
              overlayStyle={{ padding: '0 0' }}
              className="emoji-area"
              content={
                <Picker
                  set="apple"
                  title="Pick your emoji…"
                  emoji="point_up"
                  onSelect={this.addEmoji}
                  exclude={['activity', 'places', 'objects', 'symbols', 'flags']}
                />
              }
              trigger="click"
            >
              <SmileOutlined style={{ fontSize: '20px' }} />
            </Popover>
          </Col>
          <Col span={19}>
            <Input
              placeholder={intl.formatMessage({ id: 'chat.placeholder' })}
              value={textMessage}
              onChange={event => this.setState({ textMessage: event.target.value })}
              onPressEnter={this.onPressEnter}
            />
          </Col>
          <Col span={2} className="text-left">
            <SendOutlined style={{ fontSize: '20px' }} onClick={this.onPressEnter} />
          </Col>
          {/* <Col span={1} className="text-center" /> */}
        </Row>
      </>
    )
  }
}

export default injectIntl(MessageBoxFooter)
