import React from 'react'
import { Modal, Row, Col, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
// import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/messagebox/actions'
import MessageBoxFooter from './LayoutComponents/MessageBoxFooter'
import MessageBoxBody from './LayoutComponents/MessageBoxBody'

import './messagebox.less'

const mapStateToProps = ({ messagebox }) => ({ messagebox })

@connect(mapStateToProps)
class MessageBox extends React.Component {
  // constructor(props){
  //   super(props)
  //   props.dispatch({
  //     type: actions.FETCH_FEEDBACK
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isMessageBoxVisible: false,
      },
    })
  }

  render() {
    const {
      messagebox: { isMessageBoxVisible },
    } = this.props
    return (
      <div>
        <Modal
          visible={isMessageBoxVisible}
          style={{
            // top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="messageBoxModal"
          maskClosable={false}
          mask
          centered
          width="100vh"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          maskStyle={{ backgroundColor: 'rgba(15, 12, 41, 0.7)' }}
          closeIcon={
            <Tooltip title="Close">
              <CloseCircleOutlined style={{ color: '#ffe600', fontSize: '25px' }} />
            </Tooltip>
          }
          destroyOnClose
          title="Write your comments"
        >
          <Row
            gutter={24}
            id="modalContent"
            justify="space-around"
            align="middle"
            className="modalContent fullHeightScreen"
          >
            <Col span={24} className="text-center">
              <MessageBoxBody />
              <MessageBoxFooter />
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default MessageBox
