import React from 'react'
import { Row, Col } from 'antd'
import { MessageList } from 'react-chat-elements'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import './react-chat-elements.modified.css'
import styles from '../../style.module.scss'

/* eslint-disable no-unused-expressions */
const mapStateToProps = ({ messagebox }) => ({ messagebox })
@connect(mapStateToProps)
class MessageBoxBody extends React.Component {
  scrollbars = React.createRef()

  state = {
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  componentDidUpdate() {
    const { mounted } = this.state
    mounted && this.scrollbars.current.scrollToBottom()
  }

  render() {
    const {
      messagebox: { messageBoxMessages },
    } = this.props
    return (
      <>
        <Row className={styles.chatContent}>
          <Col span={24}>
            <Scrollbars ref={this.scrollbars}>
              <MessageList
                className="message-list"
                lockable
                style={{ width: '50%', fontSize: '30px' }}
                toBottomHeight="100%"
                dataSource={messageBoxMessages}
              />
            </Scrollbars>
          </Col>
        </Row>
      </>
    )
  }
}

export default MessageBoxBody
